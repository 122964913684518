function nfResponse() {
	$( document ).on( "nfFormSubmitResponse", function( event, { response } ) {
		event.preventDefault();
		let responsePopup = $( "#modal-response-popup" );
		let resultMessage = null;
		let title = null;
		const defaultTitleMessage = {
			success: "Thank you for contacting us!",
			error: "Something went wrong!"
		};

		if ( !responsePopup.length ) {
			return;
		}

		if ( Object.keys( response.errors ).length ) {
			// Error
			responsePopup.find( ".popup-title" ).addClass( "popup-title--error" );
			title = defaultTitleMessage.error;
			resultMessage = response.errors.fields[Object.keys( response.response.errors.fields )[0]];

		} else {
			// Success
			const forms = $( ".nf-form-cont" );
			let hash = "#thank-you";

			const formId = response.data.form_id;
			const currentForm = forms.filter( `#nf-form-${formId}-cont` );

			const classList = Array.from( currentForm[0].classList );
			const matchingClass = classList.find( ( item ) => item.startsWith( "js-form_" ) );

			if ( matchingClass ) {
				hash += `-${matchingClass.split( "_" )[1]}`;
			}

			history.replaceState( null, document.title, location.pathname + location.search + hash );

			responsePopup.find( ".popup-title" ).removeClass( "popup-title--error" );
			const parsedMessage = parseResponse( response.data.actions.success_message );
			title = parsedMessage.title ? parsedMessage.title : defaultTitleMessage.success;
			resultMessage = parsedMessage.message;
		}
		responsePopup.find( ".popup-title" ).html( title );
		responsePopup.find( ".popup-description .text-content" ).html( resultMessage );
		responsePopup.modal( "show" );
	} );

	$( "#modal-response-popup" ).on( "hidden.bs.modal", function() {
		history.replaceState( null, document.title, location.pathname + location.search );
	} );
}

function parseResponse( response ) {
	const titleMatch = response.match( /<h[1-6]>(.*?)<\/h[1-6]>/ );
	const messageMatch = response.match( /<p>(.*?)<\/p>/ );

	const title = titleMatch ? titleMatch[1] : "";
	const message = messageMatch ? messageMatch[1] : "";

	return { title, message };
}

$( function() {
	nfResponse();
} );
