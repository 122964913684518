function loadCleanTalk() {
	const ct = $( "#ct_public_functions-js" );

	if ( ct.length && !ct.attr( "src" ) ) {
		ct
			.attr( "src", ct.attr( "data-rocket-src" ) )
			.attr( "data-rocket-status", "executed" );
	}
}

$( function() {
	loadCleanTalk();
} );

