$.fn.ref = function( selector ) {
	return this.find( `[data-ref="${selector}"]` );
};

$( document ).ready( function() {
	$( "a, button, input[type=checkbox], input[type=radio], input[type=button], input[type=submit]" ).on( "mouseup, mouseleave", function() {
		$( this ).blur();
	} );

	$( ".modal" ).on( "show.bs.modal", function( event ) {
		let button = $( event.relatedTarget );
		let modal = $( this );

		modal.on( "hidden.bs.modal", function() {
			setTimeout( function blur() {
				button.blur();
			}, 50 );
		} );
	} );
} );
